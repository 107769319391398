<template>
  <div>
     <header id="header" v-if="!isChecked">
          <h1 class="logo"><a href="./index.html" title="meritz 여행자보험"><strong>meritz</strong> 여행자보험</a></h1>
    <NavbarComponent></NavbarComponent>
    </header>
    <router-view/>

    <article class="msgPopupW" id="msgBox" role="dialog" style="display:none;">
		<div class="layerPopup msgBox">
			<header class="tltW">
				<button type="button" class="popClose" title="레이어팝업 닫기">닫기</button>
			</header>
			<p class="msg"></p>
			<div class="btnLayer">
				<button type="button" class="btnClose popClose" title="아니오">아니오</button>
				<button type="button" class="btnConfirm popConfirm" title="예">예</button>
			</div>
		</div>
	</article>
  
  </div>
</template>

<script>

import NavbarComponent from '@/components/layout/NavbarComponent'

export default {
  name: 'App',
  components: {
    NavbarComponent
  },
  created(){
    console.log(document.location.pathname);
    /*
    if(document.location.pathname === '/index.html' || document.location.pathname === '/'){
      this.isChecked = true;
    }
    */
  }
}
</script>
