<template>
<div>
  <main id="contents">

			<nav class="pageLink">
				<ul>
					<li class="on"><a href="#">그룹관리</a></li>
					<li><a href="#">코드관리</a></li>
				</ul>
			</nav>

			<section class="sectionCon">
				<div class="schAreaW schSection">
					<div class="schAreaF">
						<form action="#">
							<fieldset>
								<div class="mixBox schField">
									<div class="ipW" style="width:200px;"><input type="text" class="ipBox" title="찾기입력" placeholder="찾기입력"></div>
								</div> <!-- //mixBox -->
							</fieldset>
							<button type="button" class="btnL">찾기</button>
						</form>
					</div> <!-- //schAreaF -->
					<div class="btnW">
						<button type="button" class="btnL primary" onclick="groupReg();">신규그룹등록</button>
					</div>
				</div> <!-- //schAreaW -->

				<div class="tblListW">
					<table class="tblList">
						<colgroup>
							<col width="30">
							<col width="40">
							<col width="7%">
							<col width="*">
							<col width="13%">
							<col width="13%">
							<col width="13%">
							<col width="13%">
							<col width="13%">
						</colgroup>
						<thead>
							<tr>
								<th>
									<div class="chkBox single">
										<input type="checkbox" id="chkAll" name=""><label for="chkAll">전체선택</label>
									</div>
								</th>
								<th>No</th>
								<th>그룹</th>
								<th>그룹명</th>
								<th>그룹관계</th>
								<th>등록일</th>
								<th>대표코드</th>
								<th>대표코드</th>
								<th>직원등록 수</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<div class="chkBox single">
										<input type="checkbox" id="chk01" name=""><label for="chk01">선택</label>
									</div>
								</td>
								<td>001</td>
								<td>보험사</td>
								<td class="tal">메리츠</td>
								<td class="tal">메리츠</td>
								<td>YYYYMMDD</td>
								<td></td>
								<td></td>
								<td>000000</td>
							</tr>
							<tr>
								<td>
									<div class="chkBox single">
										<input type="checkbox" id="chk02" name=""><label for="chk02">선택</label>
									</div>
								</td>
								<td>001</td>
								<td>보험사</td>
								<td class="tal">메리츠</td>
								<td class="tal">메리츠</td>
								<td>YYYYMMDD</td>
								<td>5사번코드</td>
								<td></td>
								<td>000000</td>
							</tr>
						</tbody>
					</table>
				</div> <!-- //tblListW -->

				<div class="paging">
					<ul>
						<li><a href="#" class="prev" title="이전">이전</a></li>
						<li><a href="#" title="1">1</a></li>
						<li><a href="#" class="current" title="2">2</a></li>
						<li><a href="#" title="3">3</a></li>
						<li><a href="#" title="4">4</a></li>
						<li><a href="#" title="5">5</a></li>
						<li><a href="#" class="next" title="다음">다음</a></li>
					</ul>
				</div>

			</section>

		</main> <!-- //contents -->

    <!-- 팝업 -->
	<article class="layerPopupW noDim" id="groupReg" role="dialog" style="display:none;">
		<div class="layerPopup layerRegPop">
			<header class="layerHeader">
				<h1 class="title">신규그룹등록</h1>
			</header>

			<div class="layerCon">
				<ul class="radListV">
					<li class="radBox"><input type="radio" id="rad01" name="rad" checked><label for="rad01">신규 대리점 등록</label></li>
					<li class="radBox"><input type="radio" id="rad02" name="rad"><label for="rad02">대리점 귀속 여행사 등록</label></li>
				</ul>
			</div> <!-- //layerCon -->

			<div class="btnLayer">
				<button type="button" class="btnConfirm popConfirm" title="신규등록">신규등록</button><button type="button" class="btnClose popClose" title="취소">취소</button>
			</div>
		</div> <!-- //layerPopup -->
	</article>
	<!-- //팝업 -->
</div>
</template>

<script>
export default {
  name: "UserScreen"
}
</script>

<style scoped>

</style>
