/* common.js */
/* eslint no-undef: "off"*/
/* eslint no-cond-assign: "off"*/
/* eslint no-empty: "off"*/
/* eslint no-prototype-builtins: "off"*/
/* eslint no-useless-escape: "off"*/
/* eslint no-unused-vars: "off"*/
/* eslint no-inner-declarations: "off"*/
/* eslint no-redeclare: "off"*/
/* eslint no-control-regex: "off"*/
var $ = require( "jquery" );
var ui = {
	init: function() {
		var _this = this;
	},
	popup: {
		zIndex: 1001,
		popCnt: 0,
		msg: function(option) {
			var _this = this,
				$msgBox = $('#msgBox'),
				$btnConfirm = $('.btnConfirm', $msgBox),
				$btnClose = $('.btnClose', $msgBox);

			// 메세지
			$('.msg', $msgBox).html(option.msg == '' ? '' : option.msg);

			$btnConfirm.addClass('lastFocus');

			// type
			if (option.type == 'alert') {
				$btnConfirm.text('확인').attr('title', '확인');
				$btnClose.hide();
			} else {
				$btnConfirm.text('예').attr('title', '예');
				$btnClose.show();
			}

			if (option.cancleBtnHide) $('.tltW .popClose', $msgBox).hide();
			else $('.tltW .popClose', $msgBox).show();

			ui.popup.open($msgBox, option);
		},
		open: function(target, option) {
			void 0;

			if (option == null) {
				var option = {};
				option.callback = null;
			}

			var _this = this,
				$layerW = $(target),
				$layer = $('.layerPopup', $layerW),
				callback = option.callback,
				tmpAppend = '<a href="javascript:void(0);" class="tmpAppend"></a>';

			_this.popCnt++;
			$('body').addClass('popupOpen');

			$layerW.css('z-index', _this.zIndex++);
			$layer.attr('tabindex', '0');

			$('.popClose', $layer).off('click').on('click', function() {
				_this.close(target);
				if (this.tagName == 'A') return false;
			});

			$('.popConfirm', $layer).off('click').on('click', function() {
				if (callback) {
					void 0;
					callback(target);
				} else {
					_this.close(target);
				}
				if (this.tagName == 'A') return false;
			});

			$('.lastFocus', $layer).off('focus').on('focus', function() {
				$layer.append(tmpAppend);
				$('.tmpAppend', $layer).focus(function() {
					$layer.attr('tabindex', '0').focus();
				});
			});

			$layer.off('focus').on('focus', function() {
				//console.log('focus');
				$('.tmpAppend', $layer).remove();
				$(document).on('keydown', function(e) {
					if (e.keyCode == 9 && e.shiftKey) {
						$layer.append(tmpAppend);
						$('.tmpAppend', $layer).focus();
					}
				});
			}).blur(function() {
				$(document).off('keydown');
			});

			$layerW.show();

			if (!$layer.hasClass('toastMsg') && !view) $layer.focus();

			return false;
		},
		close: function(target) {
			void 0;
			var _this = this;
			_this.popCnt--;
			if (_this.popCnt == 0) $('body').removeClass('popupOpen');

			$('.layerPopup', target).removeAttr('tabindex')
			$(target).hide();
		}
	},
	tmp: function() {}
};

$(document).ready(function() {
	ui.init();
});