import {createApp} from 'vue'
import App from './App.vue'
import router from "@/routers";
import axios from "axios";

import './assets/css/reset.css';
import './assets/css/common.css';
import './assets/css/dev.css';
import './assets/js/lib/jquery-1.11.2.min.js';
import './assets/js/lib/jquery-ui.min.js';
import './assets/js/lib/jquery.ui.datepicker-ko.js';
import ui from './assets/js/common.js' 


axios.defaults.baseURL = 'https://meritz-dev.itechs.io'
//axios.defaults.baseURL = 'http:///172.30.1.10'
// App 생성
const app = createApp(App)
app.config.globalProperties.axios = axios;

// App에 라우터 추가
// 어떤 컴퍼넌트에서든 this.$router 로 접근 가능하다
app.use(router)
app.use(ui);
app.mount('#wrap')
