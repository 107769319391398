<template>
<div>
  <main id="contents">

			<section class="section">
				<div class="schAreaW">
					<div class="schAreaF">
						<form action="#">
							<fieldset>
								<div class="mixBox schField">
									<div class="selW" style="width:150px;">
										<select name="" id="" class="selBox" title="등록번호">
											<option value="">등록번호</option>
										</select>
									</div>
									<div class="ipW" style="width:220px;"><input type="text" class="ipBox" title="찾기입력" placeholder="찾기입력"></div>

									<div class="mixBox period">
										<div class="ipW"><input type="text" id="estimateStdt" class="ipBox ipDate" title="조회시작일" placeholder="YYYY.MM.DD"></div>
										<span class="scTxt">~</span>
										<div class="ipW"><input type="text" id="estimateEddt" class="ipBox ipDate" title="조회종료일" placeholder="YYYY.MM.DD"></div>
									</div>

								</div> <!-- //mixBox -->
							</fieldset>
							<button type="button" class="btnL">조회</button>
						</form>
					</div> <!-- //schAreaF -->

					<div class="btnW">
						<button type="button" class="btnL">견적서 출력</button>
						<button type="button" class="btnL" onclick="fn_del();">삭제</button>
					</div>
				</div> <!-- //schAreaW -->

				<div class="tblListW">
					<table class="tblList">
						<colgroup>
							<col width="30">
							<col width="5.5%">
							<col width="11%">
							<col width="11%">
							<col width="11%">
							<col width="*">
							<col width="11%">
							<col width="11%">
							<col width="11%">
						</colgroup>
						<thead>
							<tr>
								<th>
									<div class="chkBox single">
										<input type="checkbox" id="chkAll" name=""><label for="chkAll">전체선택</label>
									</div>
								</th>
								<th>No</th>
								<th>등록일</th>
								<th>여행사</th>
								<th>등록번호(견적)</th>
								<th>추가정보</th>
								<th>피보험자</th>
								<th>보험기간</th>
								<th>시작일</th>
							</tr>
						</thead>
						<tbody id="contractList">
							
						</tbody>
					</table>
				</div> <!-- //tblListW -->

				<div class="paging">
					<ul>
						<li><a href="#" class="prev" title="이전">이전</a></li>
						<li><a href="#" title="1">1</a></li>
						<li><a href="#" class="current" title="2">2</a></li>
						<li><a href="#" title="3">3</a></li>
						<li><a href="#" title="4">4</a></li>
						<li><a href="#" title="5">5</a></li>
						<li><a href="#" class="next" title="다음">다음</a></li>
					</ul>
				</div>
			</section>

		</main> <!-- //contents -->

</div>
</template>

<script>
var $ = require( "jquery" );

export default {
  name: "EstimateListScreen",
	created(){
	this.getSelectData() 
	},
	methods : {
		getSelectData() {			
			this.axios.get('/mngr/contract/selectContractList.do')
			.then(function(res){
				var html='';
				$.each(res.data.result, function(index, item) {
					html += '<tr>';
					html += '	<td><div class="chkBox single"><input type="checkbox" id="chk0"'+index+' name=""><label for="chk0"'+index+'>선택</label></div></td>';
					html += '    <td>'+item.id+'</td>';
					html += '    <td>'+item.id+'</td>';
					html += '    <td>'+item.id+'</td>';
					html += '    <td>'+item.id+'</td>';
					html += '    <td>'+item.id+'</td>';
					html += '    <td>'+item.id+'</td>';
					html += '    <td>'+item.id+'</td>';
					html += '    <td>'+item.id+'</td>';
					html += '</tr>';
					$('#contractList').append(html);
				});
				
			}).catch(function(e){
				console.error(e);
			})
		}
	},
}
$(function() {
	
	
});
	/*
	function fn_del() {
		var option = {
			type: 'confirm',
			msg: '선택하신 견적을 삭제하시겠습니까?<br>삭제한 정보는 복구되지 않습니다.',
			callback: fn_callback
		}

		ui.popup.msg(option);
	}

	function fn_callback(target) {
		alert('callback');
		ui.popup.close(target);
	}
*/
</script>

<style scoped>

</style>
