<template>
<div>
  <nav id="gnb">
    <ul>
      <li><router-link to="/contract">견적&amp;계약</router-link></li>
      <li><router-link to="/estimate">견적목록</router-link></li>
      <li><router-link to="/endorsement">계약목록</router-link></li>
      <li><router-link to="/customer">고객검색</router-link></li>
      <li><router-link to="/receipt">영수(정산)</router-link></li>
      <li><router-link to="/performance">실적조회</router-link></li>
      <li><router-link to="/claim">보상조회</router-link></li>
      <li><router-link to="/admin">관리자</router-link></li>
    </ul>
  </nav>
  <p class="userInfo">사번정보 (대리점)<br>{{now}}</p>
</div>
</template>

<script>
var $ = require( "jquery" );

export default {
  name: "NavbarComponent",
  data() {
    
    return {
      now : ""
    }
  },
  created(){
    this.nowDate()
	},
  mounted() {
    $('#gnb li').click(function(){
        $('#gnb li').removeClass('on');
        $(this).addClass('on')
  
        $('.ipDate').datepicker({
          showOn: "button",
        });

        $(".ipDate").removeClass('hasDatepicker').datepicker(); 
        
    })

    let url = window.location.href;
    $('#gnb li a').filter(function() {
      return this.href == url;
    }).parent().addClass('on');
  },
  methods : {
   
    nowDate() { 
      setInterval(() => {
        var js_date = new Date();
        var year = js_date.getFullYear();
        var month = js_date.getMonth() + 1;
        var day = js_date.getDate();
        var hours = js_date.getHours();
        var minutes = js_date.getMinutes();

        if(month < 10){
          month = '0' + month;
        }
        if(day < 10){
          day = '0' + day;
        }
        if(hours < 10){
          hours = '0' + hours;
        }
        if(minutes < 10){
          minutes = '0' + minutes;
        }

        this.now =  year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
      }, 1000)
    }
  },
  setup() {
    
  }
}
</script>
