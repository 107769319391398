<template>
<div>
<section class="topInfoW">
	<div class="tblInfoW">
		<table class="tbl">
			<colgroup>
				<col width="95">
				<col width="*">
				<col width="105">
				<col width="*">
				<col width="90">
				<col width="*">
			</colgroup>
			<tbody>
				<tr>
					<th>대리점</th>
					<td>
						<div class="ipW"><input type="text" class="ipBox autoValue" title="대리점" value="대리점명" readonly></div>
						<!-- 자동출력되는 경우 : class autoValue 추가 -->
					</td>
					<th>작성자</th>
					<td>
						<div class="ipW"><input type="text" class="ipBox autoValue" title="작성자" value="작성자 코드(사번)" readonly></div>
					</td>
					<th>작성일</th>
					<td>
						<div class="ipW"><input type="text" class="ipBox autoValue" title="작성일" value="YYYY.MM.DD HH:mm" readonly></div>
					</td>
				</tr>
				<tr>
					<th>계약담당자</th>
					<td>
						<div class="selW">
							<select name="" id="" class="selBox" title="계약담당자">
								<option value="">계약담당자</option>
							</select>
						</div>
					</td>
					<th>계약그룹번호</th>
					<td>
						<div class="ipW"><input type="text" class="ipBox" title="계약그룹번호" value="계약그룹번호" disabled></div>
					</td>
					<th>견적번호</th>
					<td>
						<div class="ipW"><input type="text" class="ipBox" title="견적번호" value="견적번호"></div>
					</td>
				</tr>
			</tbody>
		</table>
	</div> <!-- //tblInfoW -->
</section>

<main id="contents">

	<section class="section">
		<div class="tblW">
			<table class="tbl">
				<colgroup>
					<col width="110">
					<col width="*">
					<col width="110">
					<col width="*">
				</colgroup>
				<tbody>
					<tr>
						<th>계약자(여행사)</th>
						<td>
							<div class="selW">
								<select name="" id="affiliateList" class="selBox" title="계약자(여행사)" @change="getAffiliateInfo()">
									<option value="">여행사명</option>
								</select>
							</div>
						</td>
						<th>대표증권번호</th>
						<td>
							<div class="ipW"><input type="text" id="policyNo" class="ipBox" title="대표증권번호" placeholder="여행사 상품 대표증권번호"></div>
							<!--
							오류발생시 class : error 추가
							<div class="ipW"><input type="text" class="ipBox error" title="대표증권번호" placeholder="여행사 상품 대표증권번호"></div>
						-->
						</td>
					</tr>
					<tr>
						<th>보험기간</th>
						<td>
							<div class="mixBox period">
								<div class="ipW"><input type="text" id="contractStdt" class="ipBox ipDate" title="보험시작일" placeholder="YYYY.MM.DD"></div>
								<div class="selW">
									<select name="" id="" class="selBox" title="보험시작시간">
										<option value="">00시</option>
									</select>
								</div>
								<span class="scTxt">~</span>
								<div class="ipW"><input type="text" id="contractEddt" class="ipBox ipDate" title="보험종료일" placeholder="YYYY.MM.DD"></div>
								<div class="selW">
									<select name="" id="" class="selBox" title="보험종료시간">
										<option value="">00시</option>
									</select>
								</div>
							</div>
						</td>
						<th>보험일자</th>
						<td>
							<div class="selW" style="width:110px;">
								<select name="" id="" class="selBox" title="보험일자">
									<option value="">00일</option>
								</select>
							</div>
						</td>
					</tr>
					<tr>
						<th>추가정보(1)</th>
						<td>
							<div class="ipW"><input type="text" class="ipBox" title="추가정보(1)" placeholder="추가정보를 입력해주세요."></div>
						</td>
						<th>추가정보(2)</th>
						<td>
							<div class="ipW"><input type="text" class="ipBox" title="추가정보(2)" placeholder="추가정보를 입력해주세요."></div>
						</td>
					</tr>
					<tr>
						<th>보험상품</th>
						<td>
							<div class="mixBox prod">
								<ul class="radList">
									<li class="radBox"><input type="radio" id="prod01" name="prod" value="o" checked @click="getAffiliateInfo()"><label for="prod01">국내</label></li>
									<li class="radBox"><input type="radio" id="prod02" name="prod" value="f" @click="getAffiliateInfo()"><label for="prod02">해외</label></li>
								</ul>
								<div class="ipW">
									<input type="text" id="countrySch" class="ipBox ipSch" title="여행국가입력" placeholder="여행국가입력">
									<button type="button" class="btnSch">검색</button>

									<div class="country" style="display:none;">
										<ul>
											
										</ul>
									</div> <!-- //country -->

								</div> <!-- //ipW -->
							</div>
						</td>
						<th>여행목적</th>
						<td>
							<div class="selW">
								<select name="" id="" class="selBox" title="여행목적">
									<option value="">여행목적을 선택해주세요.</option>
								</select>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div> <!-- //tblW -->
	</section>

	<section class="section">
		<div class="planBoxW">
			<div class="planBox">
				<dl>
					<dt><strong>플랜</strong> 주니어 (0~15세 미만)</dt>
					<dd>
						<span class="txt" id="juniorPlanName">플랜을 선택해주세요.</span>
						<button type="button" class="btnS">변경</button>
					</dd>
				</dl>
			</div> <!-- //planBox -->

			<div class="planBox">
				<dl>
					<dt><strong>플랜</strong> 성인 (15세~70세 미만)</dt>
					<dd>
						<span class="txt" id="adultPlanName">플랜을 선택해주세요.</span>
						<button type="button" class="btnS">변경</button>
					</dd>
				</dl>
			</div> <!-- //planBox -->

			<div class="planBox">
				<dl>
					<dt><strong>플랜</strong> 시니어1 (70세~80세 미만)</dt>
					<dd>
						<span class="txt" id="seniorPlanName">플랜을 선택해주세요.</span>
						<button type="button" class="btnS">변경</button>
					</dd>
				</dl>
			</div> <!-- //planBox -->

			<div class="planBox">
				<dl>
					<dt><strong>플랜</strong> 시니어2 (80세~100세 미만)</dt>
					<dd>
						<span class="txt" id="senior2PlanName">플랜을 선택해주세요.</span>
						<button type="button" class="btnS">변경</button>
					</dd>
				</dl>
			</div> <!-- //planBox -->
		</div> <!-- //planBoxW -->
	</section>

	<section class="section">
		<div class="sectionTitleW">
			<div class="titleL">
				<h3 class="sectionTitle">피보험자 총 명세</h3>
				<div class="totalBox">
					<dl>
						<dt>피보험자 수</dt>
						<dd class="person">00명</dd>
					</dl>
					<dl>
						<dt>총 보험료</dt>
						<dd class="price">0원</dd>
					</dl>
				</div>
			</div>

			<div class="titleR">
				<div class="btnW">
					<button type="button" class="btnM primary">산출</button>
					<button type="button" class="btnM">피보험자 추가</button>
					<button type="button" class="btnM">삭제</button>
				</div>
			</div>
		</div> <!-- //sectionTitleW -->

		<div class="listBox" style="max-height:324px;">
			<div class="tblListW">
				<table class="tblList">
					<colgroup>
						<col width="30">
						<col width="11%">
						<col width="14%">
						<col width="14%">
						<col width="7%">
						<col width="7%">
						<col width="10%">
						<col width="*">
						<col width="13%">
					</colgroup>
					<thead>
						<tr>
							<th class="str">
								<div class="chkBox single">
									<input type="checkbox" id="chkAll" name=""><label for="chkAll">전체선택</label>
								</div>
							</th>
							<th class="str">이름(국문)</th>
							<th class="str">이름(영문)</th>
							<th class="str">주민번호</th>
							<th>성별</th>
							<th>나이</th>
							<th>구분</th>
							<th>플랜명</th>
							<th>보험료</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>1</td>
							<td class="tal">김아무거나</td>
							<td></td>
							<td>000000-1******</td>
							<td></td>
							<td>00세</td>
							<td>주니어</td>
							<td>
								<div class="mixBox plan">
									<span class="txt">플랜명</span>
									<button type="button" class="btnXS">변경</button>
								</div>
							</td>
							<td class="tar">000,000</td>
						</tr>
						<tr>
							<td>2</td>
							<td class="tal">김아무거나</td>
							<td></td>
							<td>000000-1******</td>
							<td></td>
							<td>00세</td>
							<td>주니어</td>
							<td>
								<div class="mixBox plan">
									<span class="txt">플랜명</span>
									<button type="button" class="btnXS">변경</button>
								</div>
							</td>
							<td class="tar">000,000</td>
						</tr>
						<tr>
							<td>3</td>
							<td class="tal">김아무거나</td>
							<td></td>
							<td>000000-1******</td>
							<td></td>
							<td>00세</td>
							<td>주니어</td>
							<td>
								<div class="mixBox plan">
									<span class="txt">플랜명</span>
									<button type="button" class="btnXS">변경</button>
								</div>
							</td>
							<td class="tar">000,000</td>
						</tr>
						<tr>
							<td>4</td>
							<td class="tal">김아무거나</td>
							<td></td>
							<td>000000-1******</td>
							<td></td>
							<td>00세</td>
							<td>주니어</td>
							<td>
								<div class="mixBox plan">
									<span class="txt">플랜명</span>
									<button type="button" class="btnXS">변경</button>
								</div>
							</td>
							<td class="tar">000,000</td>
						</tr>
						<tr>
							<td>5</td>
							<td class="tal">김아무거나</td>
							<td></td>
							<td>000000-1******</td>
							<td></td>
							<td>00세</td>
							<td>주니어</td>
							<td>
								<div class="mixBox plan">
									<span class="txt">플랜명</span>
									<button type="button" class="btnXS">변경</button>
								</div>
							</td>
							<td class="tar">000,000</td>
						</tr>
						<tr>
							<td>6</td>
							<td class="tal">김아무거나</td>
							<td></td>
							<td>000000-1******</td>
							<td></td>
							<td>00세</td>
							<td>주니어</td>
							<td>
								<div class="mixBox plan">
									<span class="txt">플랜명</span>
									<button type="button" class="btnXS">변경</button>
								</div>
							</td>
							<td class="tar">000,000</td>
						</tr>
						<tr>
							<td>7</td>
							<td class="tal">김아무거나</td>
							<td></td>
							<td>000000-1******</td>
							<td></td>
							<td>00세</td>
							<td>주니어</td>
							<td>
								<div class="mixBox plan">
									<span class="txt">플랜명</span>
									<button type="button" class="btnXS">변경</button>
								</div>
							</td>
							<td class="tar">000,000</td>
						</tr>
						<tr>
							<td>8</td>
							<td class="tal">김아무거나</td>
							<td></td>
							<td>000000-1******</td>
							<td></td>
							<td>00세</td>
							<td>주니어</td>
							<td>
								<div class="mixBox plan">
									<span class="txt">플랜명</span>
									<button type="button" class="btnXS">변경</button>
								</div>
							</td>
							<td class="tar">000,000</td>
						</tr>
						<tr>
							<td>9</td>
							<td class="tal">김아무거나</td>
							<td></td>
							<td>000000-1******</td>
							<td></td>
							<td>00세</td>
							<td>주니어</td>
							<td>
								<div class="mixBox plan">
									<span class="txt">플랜명</span>
									<button type="button" class="btnXS">변경</button>
								</div>
							</td>
							<td class="tar">000,000</td>
						</tr>
						<tr>
							<td>10</td>
							<td class="tal">김아무거나</td>
							<td></td>
							<td>000000-1******</td>
							<td></td>
							<td>00세</td>
							<td>주니어</td>
							<td>
								<div class="mixBox plan">
									<span class="txt">플랜명</span>
									<button type="button" class="btnXS">변경</button>
								</div>
							</td>
							<td class="tar">000,000</td>
						</tr>
						<tr>
							<td>11</td>
							<td class="tal">김아무거나</td>
							<td></td>
							<td>000000-1******</td>
							<td></td>
							<td>00세</td>
							<td>주니어</td>
							<td>
								<div class="mixBox plan">
									<span class="txt">플랜명</span>
									<button type="button" class="btnXS">변경</button>
								</div>
							</td>
							<td class="tar">000,000</td>
						</tr>
					</tbody>
				</table>
			</div> <!-- //tblListW -->
		</div> <!-- //listBox -->
	</section>

	<div class="botBtnW">
		<button type="button" class="btnL primary">견적 등록</button>
		<button type="button" class="btnL primary">계약 등록</button>
		<!-- <button type="button" class="btnL primary" disabled>계약 등록</button> -->
	</div>

</main> <!-- //contents -->

	<!-- 팝업 -->
	<article class="layerPopupW" id="domesticDangerNotice" role="dialog" style="display:none;">
		<div class="layerPopup dangerNoticePop">
			<header class="layerHeader">
				<h1 class="title">국내여행 위험고지</h1>
			</header>
			<div class="layerCon">
				<dl>
					<dt>질병사망</dt>
					<dd class="txt">다른보험에 질병사망 누적 가입금액이 2억원 이상 가입되어 있습니까</dd>
					<dd class="radSelect">
						<ul class="radList">
							<li class="radBox"><input type="radio" id="domestic_01_01" name="domestic_01"><label for="domestic_01_01">예</label></li>
							<li class="radBox"><input type="radio" id="domestic_01_02" name="domestic_01" checked><label for="domestic_01_02">아니오</label></li>
						</ul>
					</dd>
				</dl>

				<dl>
					<dt>수상레져활동</dt>
					<dd class="txt">레프팅 바다낚시 스킨스쿠버등 수상레져활동을 합니까</dd>
					<dd class="radSelect">
						<ul class="radList">
							<li class="radBox"><input type="radio" id="domestic_02_01" name="domestic_02"><label for="domestic_02_01">예</label></li>
							<li class="radBox"><input type="radio" id="domestic_02_02" name="domestic_02" checked><label for="domestic_02_02">아니오</label></li>
						</ul>
					</dd>
				</dl>

				<dl>
					<dt>레져스포츠활동</dt>
					<dd class="txt">스키(스노우보드), 자건거 하이킹, 인라인, 전문 산악등반, 번지점프등 레져스포츠활동을 합니까 </dd>
					<dd class="radSelect">
						<ul class="radList">
							<li class="radBox"><input type="radio" id="domestic_03_01" name="domestic_03"><label for="domestic_03_01">예</label></li>
							<li class="radBox"><input type="radio" id="domestic_03_02" name="domestic_03" checked><label for="domestic_03_02">아니오</label></li>
						</ul>
					</dd>
				</dl>

				<dl>
					<dt>선교, 농촌봉사, 자원봉사활동</dt>
					<dd class="txt">선교활동 농촌봉사활동 자원봉사 활동등을 합니까</dd>
					<dd class="radSelect">
						<ul class="radList">
							<li class="radBox"><input type="radio" id="domestic_04_01" name="domestic_04"><label for="domestic_04_01">예</label></li>
							<li class="radBox"><input type="radio" id="domestic_04_02" name="domestic_04" checked><label for="domestic_04_02">아니오</label></li>
						</ul>
					</dd>
				</dl>

				<dl>
					<dt>사망후유장해 가입금액</dt>
					<dd class="txt">OT, MT등 학교나 기관의 단체계약시 1인당 사망 후유 장해 가입금액 2천만원 초가 계약입니까</dd>
					<dd class="radSelect">
						<ul class="radList">
							<li class="radBox"><input type="radio" id="domestic_05_01" name="domestic_05"><label for="domestic_05_01">예</label></li>
							<li class="radBox"><input type="radio" id="domestic_05_02" name="domestic_05" checked><label for="domestic_05_02">아니오</label></li>
						</ul>
					</dd>
				</dl>

				<dl>
					<dt>보험사고, 손해율</dt>
					<dd class="txt">과거 3년간 보험사고 2회이상 발생 또는 손해율 200% 초과 계약입니까</dd>
					<dd class="radSelect">
						<ul class="radList">
							<li class="radBox"><input type="radio" id="domestic_06_01" name="domestic_06"><label for="domestic_06_01">예</label></li>
							<li class="radBox"><input type="radio" id="domestic_06_02" name="domestic_06" checked><label for="domestic_06_02">아니오</label></li>
						</ul>
					</dd>
				</dl>
			</div> <!-- //layerCon -->
			<div class="btnLayer">
				<button type="button" class="btnConfirm popConfirm" title="내용을 확인하였습니다.">내용을 확인하였습니다.</button>
			</div>
		</div>
	</article>
	<!-- //팝업 -->


	<!-- 팝업 -->
	<article class="layerPopupW" id="overseasDangerNotice" role="dialog" style="display:none;">
		<div class="layerPopup dangerNoticePop">
			<header class="layerHeader">
				<h1 class="title">해외여행 위험고지</h1>
			</header>
			<div class="layerCon">
				<dl>
					<dt>보험가입 이전 해외출국</dt>
					<dd class="txt"> 피보험자가 이미 해외에 체류하고 있습니까</dd>
					<dd class="radSelect">
						<ul class="radList">
							<li class="radBox"><input type="radio" id="overseas_01_01" name="overseas_01"><label for="overseas_01_01">예</label></li>
							<li class="radBox"><input type="radio" id="overseas_01_02" name="overseas_01" checked><label for="overseas_01_02">아니오</label></li>
						</ul>
					</dd>
				</dl>

				<dl>
					<dt>질병사망</dt>
					<dd class="txt">다른 보험에 질병사망 누적 가입 금액이 2억원 이상 가입되어 있습니까</dd>
					<dd class="radSelect">
						<ul class="radList">
							<li class="radBox"><input type="radio" id="overseas_02_01" name="overseas_02"><label for="overseas_02_01">예</label></li>
							<li class="radBox"><input type="radio" id="overseas_02_02" name="overseas_02" checked><label for="overseas_02_02">아니오</label></li>
						</ul>
					</dd>
				</dl>

				<dl>
					<dt>해외체류목적</dt>
					<dd class="txt">해외에 거주하고 있거나, 해외 이주 목적으로 출국하십니까</dd>
					<dd class="radSelect">
						<ul class="radList">
							<li class="radBox"><input type="radio" id="overseas_03_01" name="overseas_03"><label for="overseas_03_01">예</label></li>
							<li class="radBox"><input type="radio" id="overseas_03_02" name="overseas_03" checked><label for="overseas_03_02">아니오</label></li>
						</ul>
					</dd>
				</dl>

				<dl>
					<dt>전쟁 및 위험지역</dt>
					<dd class="txt">여행지역이 전쟁 및 위험지역(이라크, 아프가니스탄,시리아,소말리아, 예맨 등) 이거나 외교부(www.0404.go.kr)지정 적색경보(철수권고), 흑색경보(여행금지), 특별여행주의보(철수권고), 특별여행경보(즉시대피) 지역에 포함되어 있습니까</dd>
					<dd class="radSelect">
						<ul class="radList">
							<li class="radBox"><input type="radio" id="overseas_04_01" name="overseas_04"><label for="overseas_04_01">예</label></li>
							<li class="radBox"><input type="radio" id="overseas_04_02" name="overseas_04" checked><label for="overseas_04_02">아니오</label></li>
						</ul>
					</dd>
				</dl>

				<dl>
					<dt>워킹홀리데이/ 우프/ 취업목적의 출국</dt>
					<dd class="txt">워킹홀리데이/ 우프/ 취업 목적의 출국입니까</dd>
					<dd class="radSelect">
						<ul class="radList">
							<li class="radBox"><input type="radio" id="overseas_05_01" name="overseas_05"><label for="overseas_05_01">예</label></li>
							<li class="radBox"><input type="radio" id="overseas_05_02" name="overseas_05" checked><label for="overseas_05_02">아니오</label></li>
						</ul>
					</dd>
				</dl>

				<dl>
					<dt>최근 3개월 이내에 의사로부터 진단을 받았거나 <br>치료수술 입원, 투약을 받은 경우</dt>
					<dd class="txt">최근 3개월 이내에 의사로부터 진단을 받았거나 치료 수술 입원 투약을 받을적이 있습니까</dd>
					<dd class="radSelect">
						<ul class="radList">
							<li class="radBox"><input type="radio" id="overseas_06_01" name="overseas_06"><label for="overseas_06_01">예</label></li>
							<li class="radBox"><input type="radio" id="overseas_06_02" name="overseas_06" checked><label for="overseas_06_02">아니오</label></li>
						</ul>
					</dd>
				</dl>

				<dl>
					<dt>최근 5년 이내에 입원, 수술등의 치료를 받은 경우</dt>
					<dd class="txt">최근 5년 이내에 입원, 수술 등의 치료를 받은 적이 있습니까</dd>
					<dd class="radSelect">
						<ul class="radList">
							<li class="radBox"><input type="radio" id="overseas_07_01" name="overseas_07"><label for="overseas_07_01">예</label></li>
							<li class="radBox"><input type="radio" id="overseas_07_02" name="overseas_07" checked><label for="overseas_07_02">아니오</label></li>
						</ul>
					</dd>
				</dl>

				<dl>
					<dt>체육운동 경기참여 목적으로 출국하는 경우</dt>
					<dd class="txt">체육(스키, 보드포함)활동 또는 운동경기 참여 목적으로 출국합니까</dd>
					<dd class="radSelect">
						<ul class="radList">
							<li class="radBox"><input type="radio" id="overseas_08_01" name="overseas_08"><label for="overseas_08_01">예</label></li>
							<li class="radBox"><input type="radio" id="overseas_08_02" name="overseas_08" checked><label for="overseas_08_02">아니오</label></li>
						</ul>
					</dd>
				</dl>

				<dl>
					<dt>외국영주권 소유자 및 외국인</dt>
					<dd class="txt">피보험자가 해외 영주권소유자 또는 외국인입니까</dd>
					<dd class="radSelect">
						<ul class="radList">
							<li class="radBox"><input type="radio" id="overseas_09_01" name="overseas_09"><label for="overseas_09_01">예</label></li>
							<li class="radBox"><input type="radio" id="overseas_09_02" name="overseas_09" checked><label for="overseas_09_02">아니오</label></li>
						</ul>
					</dd>
				</dl>

				<dl>
					<dt>여행운송수단</dt>
					<dd class="txt">여행의 운송수단이 선박 이륜차 자전거 입니까</dd>
					<dd class="radSelect">
						<ul class="radList">
							<li class="radBox"><input type="radio" id="overseas_10_01" name="overseas_10"><label for="overseas_10_01">예</label></li>
							<li class="radBox"><input type="radio" id="overseas_10_02" name="overseas_10" checked><label for="overseas_10_02">아니오</label></li>
						</ul>
					</dd>
				</dl>
			</div> <!-- //layerCon -->
			<div class="btnLayer">
				<button type="button" class="btnConfirm popConfirm" title="내용을 확인하였습니다.">내용을 확인하였습니다.</button>
			</div>
		</div>
	</article>
	<!-- //팝업 -->

	<!-- 팝업 -->
	<article class="layerPopupW" id="planSelect" role="dialog" style="display:none;">
		<div class="layerPopup planSelectPop">
			<header class="layerHeader">
				<h1 class="title">여행자 보험 상품 플랜 선택</h1>
				<button type="button" class="popClose" title="레이어팝업 닫기">닫기</button>
			</header>
			<div class="layerCon">

				<div class="schAreaW">
					<form action="#">
						<ul class="radList">
							<li class="radBox"><input type="radio" id="plan01_01" name="plan01" checked><label for="plan01_01">전체</label></li>
							<li class="radBox"><input type="radio" id="prod01_02" name="plan01"><label for="prod01_02">주니어</label></li>
							<li class="radBox"><input type="radio" id="prod01_03" name="plan01"><label for="prod01_03">성인</label></li>
							<li class="radBox"><input type="radio" id="prod01_04" name="plan01"><label for="prod01_04">시니어(1)</label></li>
							<li class="radBox"><input type="radio" id="prod01_05" name="plan01"><label for="prod01_05">시니어(2)</label></li>
						</ul>

						<div class="schAreaF">
							<fieldset>
								<div class="mixBox schField">
									<p class="label">플랜명</p>
									<div class="ipW" style="width:220px;"><input type="text" class="ipBox" title="플랜명" placeholder="플랜명"></div>
								</div> <!-- //mixBox -->
							</fieldset>
							<button type="button" class="btnL" onclick="fn_planAllpy();">플랜적용</button>
						</div> <!-- //schAreaF -->
					</form>

					<div class="btnW">
						<button type="button" class="btnL" onclick="fn_planFree();">여행사 자율 플랜</button>
					</div>
				</div> <!-- //schAreaW -->

				<div class="tblPlanW">
					<div class="tblListW rowTitle">
						<table class="tblList">
							<thead>
								<tr>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th>보장명(담보) 1</th>
								</tr>
								<tr>
									<th>보장명(담보) 2</th>
								</tr>
								<tr>
									<th>보장명(담보) 3</th>
								</tr>
								<tr>
									<th>보장명(담보) 4</th>
								</tr>
								<tr>
									<th>보장명(담보) 5</th>
								</tr>
								<tr>
									<th>보장명(담보) 6</th>
								</tr>
								<tr>
									<th>보장명(담보) 7</th>
								</tr>
								<tr>
									<th>보장명(담보) 8</th>
								</tr>
								<tr>
									<th>보장명(담보) 9</th>
								</tr>
								<tr>
									<th>보장명(담보) 10</th>
								</tr>
								<tr>
									<th>보장명(담보) 11</th>
								</tr>
								<tr>
									<th>보장명(담보) 12</th>
								</tr>
								<tr>
									<th>보장명(담보) 13</th>
								</tr>
								<tr>
									<th>보장명(담보) 14</th>
								</tr>
								<tr>
									<th>보장명(담보) 15</th>
								</tr>
							</tbody>
						</table>
					</div>

					<div class="tblListW rowList">
						<table class="tblList hScList" style="min-width:650px;">
							<colgroup>
								<col width="*">
								<col width="*">
								<col width="*">
								<col width="*">
								<col width="*">
								<col width="*">
								<col width="*">
							</colgroup>
							<thead>
								<tr>
									<th>[플랜명]</th>
									<th class="cellSelect">[플랜명]</th>
									<th>[플랜명]</th>
									<th>[플랜명]</th>
									<th>[플랜명]</th>
									<th>[플랜명]</th>
									<th>[플랜명]</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>가입금액</td>
									<td class="cellSelect"></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td></td>
									<td class="cellSelect"></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td></td>
									<td class="cellSelect"></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td></td>
									<td class="cellSelect"></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td></td>
									<td class="cellSelect"></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td></td>
									<td class="cellSelect"></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td></td>
									<td class="cellSelect"></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td></td>
									<td class="cellSelect"></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td></td>
									<td class="cellSelect"></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td></td>
									<td class="cellSelect"></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td></td>
									<td class="cellSelect"></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td></td>
									<td class="cellSelect"></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td></td>
									<td class="cellSelect"></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td></td>
									<td class="cellSelect"></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td></td>
									<td class="cellSelect"></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
							</tbody>
						</table>
					</div> <!-- //tblListW -->

				</div> <!-- //tblPlanW -->

			</div> <!-- //layerCon -->
		</div>
	</article>
	<!-- //팝업 -->


	<!-- 팝업 -->
	<article class="layerPopupW" id="planSelectFree" role="dialog" style="display:none;">
		<div class="layerPopup planSelectPop">
			<header class="layerHeader">
				<h1 class="title">여행자 보험 상품 플랜 선택(여행사 자율설계)</h1>
				<button type="button" class="popClose" title="레이어팝업 닫기">닫기</button>
			</header>
			<div class="layerCon">

				<div class="schAreaW">
					<form action="#">
						<ul class="radList">
							<li class="radBox"><input type="radio" id="plan02_01" name="plan02" checked><label for="plan02_01">전체</label></li>
							<li class="radBox"><input type="radio" id="prod02_02" name="plan02"><label for="prod02_02">주니어</label></li>
							<li class="radBox"><input type="radio" id="prod02_03" name="plan02"><label for="prod02_03">성인</label></li>
							<li class="radBox"><input type="radio" id="prod02_04" name="plan02"><label for="prod02_04">시니어(1)</label></li>
							<li class="radBox"><input type="radio" id="prod02_05" name="plan02"><label for="prod02_05">시니어(2)</label></li>
						</ul>

						<div class="schAreaF">
							<fieldset>
								<div class="mixBox schField">
									<p class="label">플랜명</p>
									<div class="ipW" style="width:220px;"><input type="text" class="ipBox" title="플랜명" placeholder="플랜명"></div>
								</div> <!-- //mixBox -->
							</fieldset>
							<button type="button" class="btnL" onclick="fn_planAllpy();">플랜적용</button>
						</div> <!-- //schAreaF -->
					</form>

					<div class="btnW">
						<button type="button" class="btnL primary" onclick="fn_planDel();">삭제</button>
					</div>
				</div> <!-- //schAreaW -->

				<div class="tblPlanW">
					<div class="tblListW rowTitle">
						<table class="tblList">
							<thead>
								<tr>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th>보장명(담보) 1</th>
								</tr>
								<tr>
									<th>보장명(담보) 2</th>
								</tr>
								<tr>
									<th>보장명(담보) 3</th>
								</tr>
								<tr>
									<th>보장명(담보) 4</th>
								</tr>
								<tr>
									<th>보장명(담보) 5</th>
								</tr>
								<tr>
									<th>보장명(담보) 6</th>
								</tr>
								<tr>
									<th>보장명(담보) 7</th>
								</tr>
								<tr>
									<th>보장명(담보) 8</th>
								</tr>
								<tr>
									<th>보장명(담보) 9</th>
								</tr>
								<tr>
									<th>보장명(담보) 10</th>
								</tr>
								<tr>
									<th>보장명(담보) 11</th>
								</tr>
								<tr>
									<th>보장명(담보) 12</th>
								</tr>
								<tr>
									<th>보장명(담보) 13</th>
								</tr>
								<tr>
									<th>보장명(담보) 14</th>
								</tr>
								<tr>
									<th>보장명(담보) 15</th>
								</tr>
							</tbody>
						</table>
					</div>

					<div class="tblListW rowList">
						<table class="tblList hScList" style="min-width:650px;">
							<colgroup>
								<col width="180">
								<col width="*">
								<col width="*">
								<col width="*">
								<col width="*">
								<col width="*">
								<col width="*">
							</colgroup>
							<thead>
								<tr>
									<th>
										<div class="ipW"><input type="text" class="ipBox" title="플랜명" placeholder="플랜명 작성"></div>
									</th>
									<th>[플랜명]</th>
									<th>[플랜명]</th>
									<th>[플랜명]</th>
									<th>[플랜명]</th>
									<th>[플랜명]</th>
									<th>[플랜명]</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<div class="selW">
											<select name="" id="" class="selBox" title="가입금액설정">
												<option value="">가입금액설정</option>
											</select>
										</div>
									</td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>
										<div class="selW">
											<select name="" id="" class="selBox" title="가입금액설정">
												<option value="">가입금액설정</option>
											</select>
										</div>
									</td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>
										<div class="selW">
											<select name="" id="" class="selBox" title="가입금액설정">
												<option value="">가입금액설정</option>
											</select>
										</div>
									</td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>
										<div class="selW">
											<select name="" id="" class="selBox" title="가입금액설정">
												<option value="">가입금액설정</option>
											</select>
										</div>
									</td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>
										<div class="selW">
											<select name="" id="" class="selBox" title="가입금액설정">
												<option value="">가입금액설정</option>
											</select>
										</div>
									</td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>
										<div class="selW">
											<select name="" id="" class="selBox" title="가입금액설정">
												<option value="">가입금액설정</option>
											</select>
										</div>
									</td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>
										<div class="selW">
											<select name="" id="" class="selBox" title="가입금액설정">
												<option value="">가입금액설정</option>
											</select>
										</div>
									</td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>
										<div class="selW">
											<select name="" id="" class="selBox" title="가입금액설정">
												<option value="">가입금액설정</option>
											</select>
										</div>
									</td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>
										<div class="selW">
											<select name="" id="" class="selBox" title="가입금액설정">
												<option value="">가입금액설정</option>
											</select>
										</div>
									</td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>
										<div class="selW">
											<select name="" id="" class="selBox" title="가입금액설정">
												<option value="">가입금액설정</option>
											</select>
										</div>
									</td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>
										<div class="selW">
											<select name="" id="" class="selBox" title="가입금액설정">
												<option value="">가입금액설정</option>
											</select>
										</div>
									</td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>
										<div class="selW">
											<select name="" id="" class="selBox" title="가입금액설정">
												<option value="">가입금액설정</option>
											</select>
										</div>
									</td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>
										<div class="selW">
											<select name="" id="" class="selBox" title="가입금액설정">
												<option value="">가입금액설정</option>
											</select>
										</div>
									</td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>
										<div class="selW">
											<select name="" id="" class="selBox" title="가입금액설정">
												<option value="">가입금액설정</option>
											</select>
										</div>
									</td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>
										<div class="selW">
											<select name="" id="" class="selBox" title="가입금액설정">
												<option value="">가입금액설정</option>
											</select>
										</div>
									</td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
							</tbody>
						</table>
					</div> <!-- //tblListW -->

				</div> <!-- //tblPlanW -->

			</div> <!-- //layerCon -->
		</div>
	</article>
	<!-- //팝업 -->

	<!-- 팝업 -->
	<article class="layerPopupW noDim" id="insuredPerson" role="dialog" style="display:none;">
		<div class="layerPopup insuredPersonPop">
			<header class="layerHeader">
				<h1 class="title">피보험자 추가</h1>
				<button type="button" class="popClose" title="레이어팝업 닫기">닫기</button>
			</header>
			<div class="layerCon">
				<div class="btnAdd">
					<button type="button">1</button>
					<button type="button">2</button>
					<button type="button">3</button>
					<button type="button">4</button>
					<button type="button">5</button>
					<button type="button">6</button>
					<button type="button">7</button>
					<button type="button">8</button>
					<button type="button">9</button>
					<button type="button">0</button>
				</div>

				<dl>
					<dt>추가인원</dt>
					<dd>
						<div class="ipW"><input type="text" class="ipBox" title="추가인원" placeholder="00명"></div>
					</dd>
				</dl>
			</div> <!-- //layerCon -->

			<div class="btnLayer">
				<button type="button" class="btnConfirm popConfirm" title="추가하기">추가하기</button>
			</div>
		</div>
	</article>
	<!-- //팝업 -->

	<article class="msgPopupW" id="msgBox" role="dialog" style="display:none;">
		<div class="layerPopup msgBox">
			<header class="tltW">
				<button type="button" class="popClose" title="레이어팝업 닫기">닫기</button>
			</header>
			<p class="msg"></p>
			<div class="btnLayer">
				<button type="button" class="btnClose popClose" title="아니오">아니오</button>
				<button type="button" class="btnConfirm popConfirm" title="예">예</button>
			</div>
		</div>
	</article>

	<!-- 팝업 -->
	<article class="layerPopupW noDim" id="progress" role="dialog" style="display:none;">
		<div class="layerPopup progressPop">
			<p class="txt">보험료 산출 중 입니다.</p>
			<p class="progressTxt"><strong>50</strong>/100</p>

			<div class="progressBar">
				<span class="ing" style="width:50%;"></span>
			</div>
		</div>
	</article>
	<!-- //팝업 -->

</div>
</template>

<script>
/* eslint no-unused-vars: "off"*/
var $ = require("jquery");
const onlyDateFromDateObject = (dateObject) => {
  return new Date(dateObject.getFullYear(), dateObject.getMonth(), dateObject.getDate(), 0, 0, 0, 0)
}
const unixTimestampToDate = (unixTimestamp) => {
  const dateTimeObject = new Date(unixTimestamp)
  return onlyDateFromDateObject(dateTimeObject)
}
const sortObjectByEndDateValue = (before, after) => {
  if ( before['policyEnd'] < after['policyEnd']){
    return 1;
  }
  if ( before['policyEnd'] > after['policyEnd']){
    return -1;
  }
  return 0;
}

export default {
	name: "ContractScreen",
	data() {
		return {
			
		};
	},
	created(){
		$('#gnb li:first-child').addClass('on');
		this.getAffiliateSelectData() 
	},
	mounted() {
		//this.paramsData = JSON.parse(this.$route.query.data)
		$('#countrySch').val(this.countrySch)
		
	},
	
	methods : {
		getAffiliateSelectData() {
			this.axios.get('/group/affiliate.do')
			.then(function(res){
				$('#affiliateList').children('option:not(:first)').remove();
				var laborOption = "";
				$.each(res.data.result, function(index, item) {
					laborOption = "<option value='" + item.affiliateId + "'>" + item.affiliateName + "</option>";
					$("#affiliateList").append(laborOption);
				});
			}).catch(function(e){
				console.error(e);
			})
		},
		
		getAffiliateInfo() {
			var affiliateId = $("#affiliateList option:selected").val();
			var policyType =  $("input[name='prod']:checked").val();

			this.axios.get('/group/affiliate/info.do', {
				params: {
					affiliateId: affiliateId,
					policyType: policyType
				}
			})
			.then(function(res){
				var result = res.data.result;
				var planInfo = res.data.planInfo;
				const now = onlyDateFromDateObject(new Date())
				let currentPolicy = []
				result.forEach(policy => {
					var policyStart = unixTimestampToDate(policy.startDate)
					var policyEnd = unixTimestampToDate(policy.endDate)
					//console.log(policyStart)
					//console.log(policyEnd)
					//console.log(now)
					policy.policyStart = policyStart
					policy.policyEnd = policyEnd
					if (policyStart.valueOf() <= now.valueOf()  && now.valueOf() <= policyEnd.valueOf()) {
					policy['current'] = true
					currentPolicy.push(policy)
					} else {
					policy['current'] = false
					}
				})
				//console.log(currentPolicy[0]);
				$('#policyNo').val(currentPolicy[0].policyNo);
				$('#contractStdt').val(currentPolicy[0].startDate);
				$('#contractEddt').val(currentPolicy[0].endDate);
				//기본 플랜 셋팅
				$.each(planInfo, function(index, item) {
					if(item.planType == 'oj' || item.planType == 'fj') {
						$('#juniorPlanName').text(item.planName);
					}
					if(item.planType == 'oa' || item.planType == 'fa') {
						$('#adultPlanName').text(item.planName);
					}
					if(item.planType == 'os1' || item.planType == 'fs1') {
						$('#seniorPlanName').text(item.planName);
					}
					if(item.planType == 'os2' || item.planType == 'fs2') {
						$('#senior2PlanName').text(item.planName);
					}
				});
				if (currentPolicy.length === 0) {
					return null
				} else if (currentPolicy.length === 1) {
					return currentPolicy[0]
				} else {
					currentPolicy.sort(sortObjectByEndDateValue)
					return currentPolicy[0]
				}
			}).catch(function(e){
				console.error(e);
			})
		}
		
	},
	setup() {
	// setup 안에서는 this에 접근할 수 없다. 따라서...
	// https://router.vuejs.org/guide/advanced/composition-api.html#accessing-the-router-and-current-route-inside-setup
	//mounted -> onMounted로 쓰임
	}
}


$(function() {

	$('.ipDate').datepicker({
		showOn: "button",
	});

	$(".ipDate").removeClass('hasDatepicker').datepicker(); 


});

</script>

<style scoped>

</style>
