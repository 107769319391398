<template>
<div>
  <main id="contents">

			<section class="sectionCon">
				<div class="schAreaW schSection">
					<div class="schAreaF">
						<form action="#">
							<fieldset>
								<div class="mixBox schField">
									<p class="label">실적기간</p>
									<div class="mixBox period">
										<div class="ipW"><input type="text" class="ipBox ipDate" title="조회시작일" placeholder="YYYY.MM.DD"></div>
										<span class="scTxt">~</span>
										<div class="ipW"><input type="text" class="ipBox ipDate" title="조회종료일" placeholder="YYYY.MM.DD"></div>
									</div>
								</div> <!-- //mixBox -->
							</fieldset>
						</form>
					</div> <!-- //schAreaF -->

					<div class="btnW">
						<button type="button" class="btnExcel">엑셀다운로드</button>
					</div>
				</div> <!-- //schAreaW -->
			</section>

			<section class="sectionCon">
				<div class="titleW">
					<h2 class="title">실적정보</h2>
				</div>

				<div class="tblInfoListW">
					<table class="tblInfoList">
						<colgroup>
							<col width="*">
							<col width="*">
							<col width="13%">
							<col width="13%">
							<col width="13%">
							<col width="13%">
							<col width="13%">
						</colgroup>
						<thead>
							<tr>
								<th>지점명</th>
								<th>사번코드</th>
								<th>건수</th>
								<th>보험료</th>
								<th>수정</th>
								<th>환급</th>
								<th>실적</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<div class="txtBox">지점명</div>
								</td>
								<td>
									<div class="txtBox"></div>
								</td>
								<td>
									<div class="txtBox"></div>
								</td>
								<td>
									<div class="txtBox"></div>
								</td>
								<td>
									<div class="txtBox"></div>
								</td>
								<td>
									<div class="txtBox"></div>
								</td>
								<td>
									<div class="txtBox"></div>
								</td>
							</tr>
						</tbody>
					</table>
				</div> <!-- //tblInfoListW -->

				<div class="tblListW">
					<table class="tblList">
						<colgroup>
							<col width="30">
							<col width="40">
							<col width="*">
							<col width="*">
							<col width="8%">
							<col width="10%">
							<col width="10%">
							<col width="10%">
							<col width="10%">
						</colgroup>
						<thead>
							<tr>
								<th>
									<div class="chkBox single">
										<input type="checkbox" id="chkAll" name=""><label for="chkAll">전체선택</label>
									</div>
								</th>
								<th>No</th>
								<th>계약번호</th>
								<th>대표증권</th>
								<th>피보험자</th>
								<th>보험료</th>
								<th>환급</th>
								<th>수정</th>
								<th>실적</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<div class="chkBox single">
										<input type="checkbox" id="chk01" name=""><label for="chk01">선택</label>
									</div>
								</td>
								<td>001</td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
						</tbody>
					</table>
				</div> <!-- //tblListW -->

				<div class="paging">
					<ul>
						<li><a href="#" class="prev" title="이전">이전</a></li>
						<li><a href="#" title="1">1</a></li>
						<li><a href="#" class="current" title="2">2</a></li>
						<li><a href="#" title="3">3</a></li>
						<li><a href="#" title="4">4</a></li>
						<li><a href="#" title="5">5</a></li>
						<li><a href="#" class="next" title="다음">다음</a></li>
					</ul>
				</div>
			</section>

		</main> <!-- //contents -->
</div>
</template>

<script>
export default {
  name: "PerformanceScreen"
}
</script>

<style scoped>

</style>
