<template>
<div>
  <main id="contents">
    <section class="section">
      <div class="schAreaW">
        <div class="schAreaF">
          <form action="#">
            <fieldset>
              <div class="mixBox schField">
                <div class="selW" style="width:120px;">
                  <select name="" id="" class="selBox" title="상태">
                    <option value="">상태</option>
                  </select>
                </div>
                <div class="selW" style="width:120px;">
                  <select name="" id="" class="selBox" title="등록번호">
                    <option value="">등록번호</option>
                  </select>
                </div>
                <div class="ipW" style="width:200px;"><input type="text" class="ipBox" title="찾기입력" placeholder="찾기입력"></div>

                <div class="mixBox period">
                  <div class="ipW"><input type="text" class="ipBox ipDate" title="조회시작일" placeholder="YYYY.MM.DD"></div>
                  <span class="scTxt">~</span>
                  <div class="ipW"><input type="text" class="ipBox ipDate" title="조회종료일" placeholder="YYYY.MM.DD"></div>
                </div>

              </div> <!-- //mixBox -->
            </fieldset>
            <button type="button" class="btnL">조회</button>
          </form>
        </div> <!-- //schAreaF -->

        <div class="btnW">
          <button type="button" class="btnL">가입증명서</button>
        </div>
      </div> <!-- //schAreaW -->

      <div class="tblListW">
        <!--
        가로스크롤이 필요할 경우 min-width 값 셋팅
        <table class="tblList hScList" style="min-width:2000px;">
      -->
        <table class="tblList hScList">
          <colgroup>
            <col width="30">
            <col width="35">
            <col width="*">
            <col width="*">
            <col width="*">
            <col width="*">
            <col width="*">
            <col width="*">
            <col width="*">
            <col width="*">
            <col width="*">
            <col width="*">
          </colgroup>
          <thead>
            <tr>
              <th>
                <div class="chkBox single">
                  <input type="checkbox" id="chkAll" name=""><label for="chkAll">전체선택</label>
                </div>
              </th>
              <th>No</th>
              <th>상태</th>
              <th>여행사</th>
              <th>대표증권</th>
              <th>계약번호</th>
              <th>수정번호</th>
              <th>계약일자</th>
              <th>수정일자</th>
              <th>상품</th>
              <th>피보험자</th>
              <th>보험기간</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="chkBox single">
                  <input type="checkbox" id="chk01" name=""><label for="chk01">선택</label>
                </div>
              </td>
              <td>001</td>
              <td>계약</td>
              <td>000여행사</td>
              <td>0000000000</td>
              <td>0000000000</td>
              <td>YYYYMMDD</td>
              <td>YYYYMMDD</td>
              <td></td>
              <td></td>
              <td>홍길동</td>
              <td>YYYYMMDD ~ YYYYMMDD</td>
            </tr>
            <tr>
              <td>
                <div class="chkBox single">
                  <input type="checkbox" id="chk02" name=""><label for="chk02">선택</label>
                </div>
              </td>
              <td>001</td>
              <td>계약</td>
              <td>000여행사</td>
              <td>0000000000</td>
              <td>0000000000</td>
              <td>YYYYMMDD</td>
              <td>YYYYMMDD</td>
              <td></td>
              <td></td>
              <td>홍길동</td>
              <td>YYYYMMDD ~ YYYYMMDD</td>
            </tr>
            <tr>
              <td>
                <div class="chkBox single">
                  <input type="checkbox" id="chk03" name=""><label for="chk03">선택</label>
                </div>
              </td>
              <td>001</td>
              <td>계약</td>
              <td>000여행사</td>
              <td>0000000000</td>
              <td>0000000000</td>
              <td>YYYYMMDD</td>
              <td>YYYYMMDD</td>
              <td></td>
              <td></td>
              <td>홍길동</td>
              <td>YYYYMMDD ~ YYYYMMDD</td>
            </tr>
          </tbody>
        </table>
      </div> <!-- //tblListW -->

      <div class="paging">
        <ul>
          <li><a href="#" class="prev" title="이전">이전</a></li>
          <li><a href="#" title="1">1</a></li>
          <li><a href="#" class="current" title="2">2</a></li>
          <li><a href="#" title="3">3</a></li>
          <li><a href="#" title="4">4</a></li>
          <li><a href="#" title="5">5</a></li>
          <li><a href="#" class="next" title="다음">다음</a></li>
        </ul>
      </div>
    </section>

  </main> <!-- //contents -->
</div>
</template>

<script>
var $ = require( "jquery" );
export default {
  name: "EndorsementListScreen"
}

$(function() {


	
});
</script>

<style scoped>

</style>
