import {createRouter, createWebHashHistory} from "vue-router";
//import LoginScreen from "@/components/auth/LoginScreen";
import ContractScreen from "@/components/contract/ContractScreen";
import CustomerScreen from "@/components/customer/CustomerScreen";
import EstimateListScreen from "@/components/estimate/EstimateListScreen";
import EndorsementListScreen from "@/components/endorsement/EndorsementListScreen";
import ReceiptListScreen from "@/components/receipt/ReceiptListScreen";
import ClaimScreen from "@/components/claim/ClaimScreen";
import PerformanceScreen from "@/components/performance/PerformanceScreen";
import UserScreen from "@/components/admin/UserScreen";


  
// 라우터 정의
const routes = [
    {path: '/', component: ContractScreen},
    {path: '/contract', component: ContractScreen},
    {path: '/estimate', component: EstimateListScreen},
    {path: '/endorsement', component: EndorsementListScreen},
    {path: '/customer', component: CustomerScreen},
    {path: '/receipt', component: ReceiptListScreen},
    {path: '/claim', component: ClaimScreen},
    {path: '/performance', component: PerformanceScreen},
    {path: '/admin', component: UserScreen},
]

// 라우터 생성
const router = createRouter({
    history: createWebHashHistory(),
    routes: routes
})

export default router


