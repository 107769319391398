<template>
<div>
  <main id="contents">

			<section class="sectionCon">
				<div class="schAreaW schSection">
					<div class="schAreaF">
						<form action="#">
							<fieldset>
								<div class="mixBox schField">
									<div class="selW" style="width:145px;">
										<select name="" id="" class="selBox" title="피보험자">
											<option value="">피보험자</option>
										</select>
									</div>
									<div class="ipW" style="width:200px;"><input type="text" class="ipBox" title="찾기입력" placeholder="찾기입력"></div>
								</div> <!-- //mixBox -->
							</fieldset>
							<button type="button" class="btnL">찾기</button>
						</form>
					</div> <!-- //schAreaF -->
				</div> <!-- //schAreaW -->
			</section>

			<section class="sectionCon">
				<div class="titleW">
					<h2 class="title">조회결과</h2>
					<div class="titleR">
						<dl class="schCnt">
							<dt>조회결과</dt>
							<dd><strong>000</strong>건</dd>
						</dl>
					</div>
				</div> <!-- //titleW -->

				<div class="tblResultW">
					<table class="tblResult">
						<colgroup>
							<col width="90px">
							<col width="70px">
							<col width="80px">
							<col width="80px">
							<col width="*">
						</colgroup>
						<tbody>
							<tr>
								<td>880926</td>
								<td>홍*동</td>
								<td>모두투어</td>
								<td>국내여행</td>
								<td>대표증번</td>
							</tr>
							<tr>
								<td>880926</td>
								<td>홍*동</td>
								<td>모두투어</td>
								<td>국내여행</td>
								<td>대표증번</td>
							</tr>
							<tr>
								<td>880926</td>
								<td>홍*동</td>
								<td>모두투어</td>
								<td>국내여행</td>
								<td>대표증번</td>
							</tr>
						</tbody>
					</table>
				</div> <!-- //tblResultW -->

			</section>

			<section class="sectionCon">
				<div class="titleW">
					<h2 class="title">조회 결과 상세</h2>
				</div>

				<div class="tblW tblDetailW">
					<table class="tbl">
						<colgroup>
							<col width="110">
							<col width="*">
							<col width="110">
							<col width="*">
						</colgroup>
						<tbody>
							<tr>
								<th>진행상태</th>
								<td>
									<div class="ipW"><input type="text" class="ipBox autoValue" title="진행상태" value="진행상태" readonly></div>
								</td>
								<td colspan="2"></td>
							</tr>
							<tr>
								<th>사고번호</th>
								<td>
									<div class="ipW"><input type="text" class="ipBox autoValue" title="사고번호" value="사고번호" readonly></div>
								</td>
								<th>증권번호</th>
								<td>
									<div class="ipW"><input type="text" class="ipBox autoValue" title="증권번호" value="증권번호" readonly></div>
								</td>
							</tr>
							<tr>
								<th>상품명</th>
								<td>
									<div class="ipW"><input type="text" class="ipBox autoValue" title="상품명" value="상품명" readonly></div>
								</td>
								<th>청구유형</th>
								<td>
									<div class="ipW"><input type="text" class="ipBox autoValue" title="청구유형" value="청구유형" readonly></div>
								</td>
							</tr>
							<tr>
								<th>피해서열명</th>
								<td>
									<div class="ipW"><input type="text" class="ipBox autoValue" title="피해서열명" value="피해서열명" readonly></div>
								</td>
								<th>피해서열코드</th>
								<td>
									<div class="ipW"><input type="text" class="ipBox autoValue" title="피해서열코드" value="피해서열코드" readonly></div>
								</td>
							</tr>
							<tr>
								<th>사고일자</th>
								<td>
									<div class="ipW"><input type="text" class="ipBox autoValue" title="사고일자" value="사고일자" readonly></div>
								</td>
								<th>사고장소</th>
								<td>
									<div class="ipW"><input type="text" class="ipBox autoValue" title="사고장소" value="사고장소" readonly></div>
								</td>
							</tr>
							<tr>
								<th>최종접수일자</th>
								<td>
									<div class="ipW"><input type="text" class="ipBox autoValue" title="최종접수일자" value="최종접수일자" readonly></div>
								</td>
								<th>지급일자</th>
								<td>
									<div class="ipW"><input type="text" class="ipBox autoValue" title="지급일자" value="지급일자" readonly></div>
								</td>
							</tr>
							<tr>
								<th>원화환산<br>결정보험금</th>
								<td>
									<div class="ipW"><input type="text" class="ipBox autoValue" title="원화환산결정보험금" value="원화환산결정보험금" readonly></div>
								</td>
								<th>원화환산 <br>추산잔액</th>
								<td>
									<div class="ipW"><input type="text" class="ipBox autoValue" title="원화환산 추산잔액" value="원화환산 추산잔액" readonly></div>
								</td>
							</tr>
							<tr>
								<th>담당 파트/이름</th>
								<td>
									<div class="ipW"><input type="text" class="ipBox autoValue" title="담당 파트/이름" value="담당 파트/이름" readonly></div>
								</td>
								<th>연락처</th>
								<td>
									<div class="ipW"><input type="text" class="ipBox autoValue" title="연락처" value="연락처" readonly></div>
								</td>
							</tr>
						</tbody>
					</table>
				</div> <!-- //tblW -->
			</section>

		</main> <!-- //contents -->

</div>
</template>

<script>
export default {
  name: "ClaimScreen"
}
</script>

<style scoped>

</style>
